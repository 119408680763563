@import url("../../../node_modules/evil-icons/assets/evil-icons.css");

:root {
    --main-width: 1000px;
    --main-background-color: #fdfdfe;
    --background-color: #f8f8f8;
    --border-color: #eee;
    --text-background-color: #fff;
    --text-color: #222;
    --link-color: #3c77aa;
    --dimmed-link-color: #88958d;
    --quote-color: #666;
}
@media (prefers-color-scheme: dark) {
    :root {
        --main-background-color: #222;
        --background-color: #333;
        --text-background-color: #383838;
        --border-color: #4c4c4c;
        --text-color: #ccc;
        --link-color: #c38855;
        --dimmed-link-color: #88958d;
        --quote-color: #a2a2a2;
    }
}

* {
    box-sizing: border-box;
}

/* #region generic */

html,
body,
div,
h1,
h2,
ul,
li,
p,
form,
input,
textarea,
pre {
    margin: 0;
    padding: 0;
}
textarea {
    overflow: auto;
}
html,
body {
    width: 100%;
    height: 100%;
}

body {
    background: var(--main-background-color);
    text-size-adjust: 100%;
    word-wrap: break-word;
    color: var(--text-color);
    line-height: 1.4;
}

button,
input,
textarea {
    font-size: 1em;
    background-color: var(--text-background-color);
    color: var(--text-color);
}

html,
input,
textarea,
button {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
}
h1,
h2 {
    font-weight: normal;
}

ul {
    list-style-type: none;
}

a {
    color: var(--link-color);
    text-decoration: none;
}
img,
hr {
    border: none;
}

hr {
    background: var(--border-color);
    height: 1px;
    margin: 10px 0;
}

pre {
    background: #1e2028;
    color: #41b645;
    overflow-x: auto;
    padding: 6px 20px;
    white-space: pre;
}

pre::selection {
    background: #41b645;
    color: #1e2028;
}

/* #endregion */

/* #region overall layout */

#app {
    height: 100%;
    width: 100%;
}

#column {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 10;
    background: var(--main-background-color);
}

#content {
    padding-bottom: 66px;
}

#content_wrapper {
    margin-top: 66px;
}

#minimal_content {
    margin: 0 auto;
    min-width: 310px;
    width: auto;
}

.desktop {
    display: none;
}

.noselect {
    user-select: none;
}

*::selection {
    background: var(--link-color);
    color: var(--main-background-color);
}

#header {
    background: var(--text-background-color);
    border-bottom: 1px solid var(--border-color);
    transition: transform 0.2s;
    z-index: 10;
    position: fixed;
    width: 100%;
    top: 0;
}

#header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#footer {
    color: #999;
    font-size: 10pt;
    padding: 10px 0;
}

/* #endregion */

/* #region header internals */

#logo {
    height: 36px;
    width: 110px;
}

#logo a {
    background: url("./logo@2x.png") no-repeat !important;
    background-size: cover !important;
    border: 0;
    display: block;
    height: 36px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    width: 110px;
}

#global {
    display: flex;
    white-space: nowrap;
}

#global a {
    color: var(--dimmed-link-color);
    padding: 14px;
    border-bottom: 2px solid transparent;
}

#ctitle,
#user_wrapper {
    display: flex;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
}

#ctitle a,
#user_wrapper a {
    padding: 2px 20px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.l a {
    border-bottom: 2px solid transparent;
}

#global a.active {
    background: var(--background-color);
    border-bottom: 2px solid #ff339a;
}
#global a:hover,
#ctitle a:hover,
#user_wrapper a:hover,
.l a:hover {
    cursor: pointer;
    background-color: var(--background-color);
}
#global a:hover {
    border-bottom: 2px solid #ff339a;
}

#search input {
    background: var(--main-background-color);
    border: 1px solid var(--border-color);
    outline: none !important;
    padding: 4px;
    appearance: none;
    border-radius: 0;
}

/* #endregion */

/* #region left column internals */

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#global a,
.toolbar a {
    display: flex;
    padding: 14px;
    color: #88959d;
    align-items: center;
    vertical-align: middle;
    border-right: initial;
    border-top: 2px solid transparent;
}
.toolbar > a:hover {
    background-color: var(--background-color);
    cursor: pointer;
    border-right: initial;
    border-top: 2px solid #ff339a;
}

#column ul,
#column p,
#column hr {
    margin: 10px 0;
}

#column .margtop {
    margin-top: 15px;
}

#column .tags {
    line-height: 140%;
    padding: 12px;
    text-align: justify;
}

#column .inp {
    background: var(--main-background-color);
    border: 1px solid var(--border-color);
    outline: none !important;
    padding: 4px;
}

#column .tags h4 {
    color: #88958d;
    display: block;
    text-align: center;
    font-weight: 400;
}

#ctitle img,
.iread img,
#user_wrapper img {
    margin-right: 5px;
    vertical-align: middle;
    max-width: 48px;
    max-height: 48px;
}

#ustats li {
    font-size: 10pt;
    margin: 3px 0;
}

#column table.iread {
    width: 100%;
}

#column table.iread td {
    text-align: center;
}

#column table.iread img {
    height: 48px;
    width: 48px;
}

/* #endregion */

/* #region main content */
#content > p,
#content > h1,
#content > h2,
#minimal_content > p,
#minimal_content > h1,
#minimal_content > h2 {
    margin: 1em 0;
}

.page {
    background: #eee;
    padding: 6px;
    text-align: center;
    color: var(--dimmed-link-color);
}

/* #endregion */

/* #region article, message internals */

article {
    background: var(--text-background-color);
    border: 1px solid var(--border-color);
    line-height: 140%;
    margin-bottom: 10px;
}
article .h,
article #postmsg,
.page-content,
.msg-cont .msg-comment,
.msg-cont .msg-recomms,
.msg-cont .msg-links,
.msg-cont .msg-header {
    padding: 20px;
}
.page-content {
    overflow: auto;
}

article time {
    color: #88958d;
    font-size: 10pt;
}

article .ir {
    text-align: center;
}

article .ir a {
    cursor: zoom-in;
    display: block;
}

article .ir img, .msg-media img {
    max-width: 100%;
}
article > nav.l,
.msg-cont > nav.l {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 10pt;
}
article > nav.l a,
.msg-cont > nav.l a {
    color: #88958d;
    margin-right: 15px;
}

article .likes {
    padding-left: 20px;
}

article .replies {
    margin-left: 18px;
}

article .tags {
    margin-top: 3px;
}

.msg-tags {
    margin-top: 12px;
    min-height: 1px;
}
article .tags > a,
.msg-tags > a {
    color: #88958d;
}
article .tags > a::before,
.msg-tags > a::before {
    content: '#';
}

.badge {
    background: #eee;
    border: 1px solid #eee;
    color: #88958d;
    display: inline-block;
    font-size: 10pt;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0 10px;
}

.dimmed {
    color: var(--dimmed-link-color);
}

.l .msg-button {
    padding: 12px;
}
.l .msg-button-icon {
    display: flex;
    align-items: center;
}

.Button {
    background: #fff;
    border: 1px solid var(--border-color);
    color: #888;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 4px 10px;
    word-break: break-all;
}
.Button:hover {
    background: #f8f8f8;
}

.msgthread {
    margin-bottom: 0;
}

.msg-avatar {
    float: left;
    max-height: 48px;
    margin-right: 10px;
    max-width: 48px;
}

.msg-avatar img {
    max-height: 48px;
    vertical-align: top;
    max-width: 48px;
}

.info-avatar {
    white-space: nowrap;
}

.info-avatar img {
    max-height: 36px;
    max-width: 36px;
    padding: 6px;
    vertical-align: middle;
}

.msg-cont {
    background: var(--text-background-color);
    border: 1px solid var(--border-color);
    border-bottom: 1px solid transparent;
    line-height: 140%;
}

#replies .msg-cont {
    border-top: 1px solid transparent;
}

#replies .msg-cont:last-child {
    border-bottom: 1px solid var(--border-color);
}

.reply-new .msg-cont {
    border-right: 5px solid #0C0;
}

.msg-ts {
    font-size: small;
    vertical-align: top;
}
.msg-ts,
.msg-ts > a {
    color: #999;
}

.msg-txt {
    clear: both;
    margin: 0 0 12px;
    padding: 0 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.msg-media {
    text-align: center;
    overflow: auto;
}

.msg-links {
    color: #999;
    font-size: small;
    margin: 5px 0 0 0;
}

.msg-comments {
    color: #AAA;
    font-size: small;
    margin-top: 10px;
    overflow: hidden;
    text-indent: 10px;
}

.ta-wrapper {
    border: 1px solid #DDD;
    display: flex;
    flex-grow: 1;
}

.msg-comment {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.msg-comment-hidden {
    display: none;
}

.msg-comment textarea {
    border: 0;
    flex-grow: 1;
    outline: none !important;
    padding: 4px;
    overflow: auto;
    resize: none;
    vertical-align: top;
}

.attach-photo {
    cursor: pointer;
}

.attach-photo-active {
    color: green;
}

.msg-recomms {
    color: #AAA;
    font-size: small;
    margin-top: 10px;
    overflow: hidden;
    text-indent: 10px;
}
#replies .msg-txt,
#private-messages .msg-txt {
    margin: 0;
}

/* #endregion */

/* #region user-generated texts */

blockquote:before,
blockquote:after {
    content: "";
}
blockquote {
    border-left: 3px solid #CCC;
    color: var(--quote-color);
    display: block;
    margin: 10px 0 10px 10px;
    padding-left: 10px;
}

/* #endregion */

/* #region new message form internals */

#newmessage {
    background: #E5E5E0;
    margin-bottom: 20px;
    padding: 15px;
}

#newmessage textarea {
    border: 1px solid #CCC;
    box-sizing: border-box;
    margin: 0 0 5px 0;
    margin-top: 20px;
    max-height: 6em;
    min-width: 280px;
    padding: 4px;
    width: 100%;
}

#newmessage input {
    border: 1px solid #CCC;
    margin: 5px 0;
    padding: 2px 4px;
}

#newmessage .img {
    width: 500px;
}

#newmessage .tags {
    width: 500px;
}

#newmessage .subm {
    background: #EEEEE5;
    width: 150px;
}
@media screen and (width <= 992px) {
    #newmessage .img,
    #newmessage .tags {
        width: 100%;
    }
}

/* #endregion */

/* #region user lists */

.users {
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.users > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px 0;
    width: 220px;
}
.users img {
    height: 32px;
    margin-right: 6px;
    vertical-align: middle;
    width: 32px;
}

/* #endregion */

/* #region signup form */

.signup-h1 > img {
    margin-right: 10px;
    vertical-align: middle;
}

.signup-h1 {
    font-size: x-large;
    margin: 20px 0 10px 0;
}

.signup-h2 {
    font-size: large;
    margin: 10px 0 5px 0;
}

.signup-hr {
    margin: 20px 0;
}

/* #endregion */

/* #region PM */

.newpm {
    margin: 20px 60px 30px 60px;
}

.newpm textarea {
    resize: vertical;
    width: 100%;
}

.newpm-send input {
    width: 100px;
}

/* #endregion */

/* #region popup dialog (lightbox) */

#dialogb {
    background: var(--text-color);
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

#dialogt {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
}

#dialogw {
    z-index: 11;
    max-width: 96%;
    max-height: calc(100% - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#dialogw a {
    display: block;
}

#dialogw img {
    max-height: 100%;
    max-height: 90vh;
    max-width: 100%;
}

#dialog_header {
    width: 100%;
    height: 44px;
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.header_image {
    background: rgba(0, 0, 0, 0.28);
}

#dialogc {
    cursor: pointer;
    color: #ccc;
    padding-right: 6px;
}

.dialoglogin {
    background: var(--text-background-color);
    padding: 25px;
    width: 300px;
}

.dialog-opened {
    overflow: hidden;
}
#signemail,
#signfb,
#signvk {
    display: block;
    line-height: 32px;
    margin: 10px 0;
    text-decoration: none;
    width: 100%;
}

#signvk {
    margin-bottom: 30px;
}

.dialoglogin form {
    margin-top: 7px;
}
.signinput,
.signsubmit {
    border: 1px solid var(--border-color);
    margin: 3px 0;
    padding: 3px;
}

.signinput {
    width: 100%;
}

.signsubmit {
    width: 70px;
}

.dialogshare {
    background: var(--text-background-color);
    min-width: 300px;
    overflow: auto;
    padding: 20px;
}

.dialogl {
    background: var(--text-background-color);
    border: 1px solid #DDD;
    margin: 3px 0 20px;
    padding: 5px;
}

.dialogshare li {
    float: left;
    margin: 5px 10px 0 0;
}

.dialogshare a {
    display: block;
}

.dialogtxt {
    background: var(--text-background-color);
    padding: 20px;
}

@media screen and (width <= 480px) {
    .dialog-opened {
        position: fixed;
        width: 100%;
    }
}

/* #endregion */

/* #region modal styles */

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px 0;
}

.modal-content {
    background: var(--text-background-color);
    padding: 20px;
    max-width: 90%;
}

@media screen and (width <= 992px) {
    .modal-container {
        padding: 0;
    }
    .modal-content {
        width: 100%;
        min-height: calc(100% - 66px);
        margin-top: 66px;
        max-width: none;
    }
}

/* #endregion */

/* #region misc */

#wsthread {
    background: #CCC;
    bottom: 20px;
    cursor: pointer;
    padding: 5px 10px;
    position: fixed;
    right: 20px;
}

.sharenew {
    display: inline-block;
    line-height: 32px;
    min-height: 32px;
    min-width: 200px;
    padding: 0 12px 0 37px;
}

i[data-icon] {
    width: 25px;
    height: 25px;
}

.newmessage {
    /* textarea on the /post page */
    border: 1px solid #DDD;
    outline: none !important;
    padding: 4px;
    resize: none;
    overflow: auto;
    width: 100%;
}

/* #endregion */

/* #region footer internals */

#footer-social a {
    border: 0;
    display: inline-block;
}

/* #endregion */

/* #region settings */

fieldset {
    border: 1px dotted var(--border-color);
    margin-top: 25px;
}

/* #endregion */

/* #region embeds */

.embedContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
}
.embedContainer > * {
    box-sizing: border-box;
    margin: 3px;
}

.embedContainer .picture img {
    display: block;
}
.embedContainer img,
.embedContainer video {
    max-width: 100%;
    max-height: 80vh;
}
.embedContainer > .audio,
.embedContainer > .youtube {
    min-width: 90%;
}

.embedContainer audio {
    width: 100%;
}

.embedContainer iframe {
    overflow: hidden;
    resize: vertical;
    display: block;
}

/* #endregion */

/* #region nsfw */

article.nsfw .embedContainer img,
article.nsfw .embedContainer video,
article.nsfw .embedContainer iframe,
article.nsfw .ir img, article.nsfw .msg-media img {
    opacity: 0.1;
}
article.nsfw .embedContainer img:hover,
article.nsfw .embedContainer video:hover,
article.nsfw .embedContainer iframe:hover,
article.nsfw .ir img:hover, article.nsfw .msg-media img:hover {
    opacity: 1;
}

/* #endregion */

article p {
    margin: 10px 0 8px 0;
}
.msg,
.page,
.msg-cont {
    min-width: 280px;
    width: auto;
}

.title2 h2 {
    font-size: large;
}

.msg-comment input {
    align-self: flex-end;
    margin: 6px 0 0 0;
}

#sidebar {
    background: var(--text-background-color);
}

#signup,
#signin {
    width: 250px;
}

#signup {
    padding-top: 25px;
}

#signup>div {
    width: 100%;
    margin: 15px 0;
}

#signup>div>a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-decoration: none;
    overflow: hidden;
}

#facebook a {
    background: #1877f2;
    color: #fff;
}

.icon--ei-sc-facebook, .icon--ei-sc-vk {
    fill: #fff;
}

#vk a {
    background: #0277ff;
    color: #fff;
}

#apple a {
    background: #222;
    color: #fff;
}

#xmppinfo {
    background: #FFF;
    padding: 10px;
    display: none;
}

#signin {
    text-align: center;
}

#signinform {
    background: #FFF;
    padding: 10px 15px;
    margin-top: 15px;
    display: none;
}

input.txt {
    width: 212px;
    border: 1px solid #CCC;
    margin: 3px 0;
    padding: 3px;
}

input.submit {
    width: 70px;
    border: 1px solid #CCC;
    margin: 3px 0;
    padding: 3px;
}

.err {
    color: red;
}

.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 48px;
    border: 10px solid #ff339a;
    border-top-color: var(--background-color);
    animation: loading 1s linear infinite;
}
@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}

noscript article {
    background-image: url("matrix.jpg");
    background-color: #222;
    background-size: cover;
    color: #fff;
    min-width: 100%;
    height: 100%;
}


@media screen and (width > 600px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}

@media screen and (width > 992px) {
    @supports (position: sticky) {
        #column {
            position: sticky;
            padding: 12px;
            height: 100%;
            z-index: auto;
            overflow-y: auto;
        }
        article,
        .page,
        .msg-cont {
            width: 640px;
        }
        #sidebar_wrapper {
            position: fixed;
            width: 300px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }
        #header_wrapper,
        #content_wrapper {
            width: 1000px;
            margin: 0 auto;
        }
        #content_wrapper {
            display: flex;
            margin: 66px auto 0;
        }
        #footer {
            padding: 10px;
        }
        #content {
            padding-bottom: 12px;
            width: 100%;
        }
        .toolbar {
            flex-direction: column;
            justify-content: initial;
            border-top: initial;
        }
        .toolbar a,
        .l a {
            border-top: initial;
            border-right: 2px solid transparent;
        }
        .toolbar a:hover {
            border-top: initial;
            border-right: 2px solid #ff339a;
        }
    }
}
